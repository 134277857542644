<template>
  <div v-show="this.mask" class="mask">
    <div 
      class="modal-area" 
      :style="modalSize"
      @click.stop>
      <slot>default slot name</slot>
    </div>
  </div>  
</template>

<script>
export default {
  name: 'ModalBase',
  data() {
    return {
      modalSize: {
        width: setModalSize('width', this.modalWidth),
        // height: this.modalHeight ? this.modalHeight : '300px',
        height: setModalSize('height', this.modalHeight),
      },
    }
  },
  props: [ 'modalWidth', 'modalHeight', 'mask', 'closeModal' ],
  computed: {
    
  }
}

/**
 * モーダルのサイズを設定
 */
function setModalSize(type, size) {
  if (size == 'auto') {
    return size;
  } else if (parseInt(size) > 0) {
    return size.toString() + 'px';
  } else {
    if (type == 'width') {
      return '500px';
    } else {
      return '300px';
    }
  }
}

</script>

<style lang="sass" scoped>
.mask
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  background-color: rgba(0, 0, 0, 0.5)

.modal-area
  border-radius: 3px
  background-color: #ffffff
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  z-index: 1000
  overflow-y: scroll

/*スクロールバー全体*/
::-webkit-scrollbar
  width: 6px

/*スクロールバーの軌道*/
::-webkit-scrollbar-track

/*スクロールバーの動く部分*/
::-webkit-scrollbar-thumb
  background-color: rgba(0, 0, 50, .5)
  border-radius: 6px
  box-shadow: 0 0 0 1px rgba(255, 255, 255, .3)

</style>

<memo>
コンポーネントの呼出時には以下のプロパティを設定
:mask="SampleModalMask"
:closeModal="closeModal" *対応する関数も作成する必要あり。
  closeModal() {
    this.SampleModalMask = false;
  };
:width="" 任意
:height="" 任意
</memo>