const config = {
  sessionKey: 'flynote',
  defaultAppData: {
    appName: 'flynote',
    domain: 'https://flynote.space',
    functionUrl: 'https://flynote.space/.netlify/functions',
    selectedPageTitle: 'idea',
    config: {
      fontSize: '16px',
    },
    pages: [
      {
        pageId: '',
        parentPageId: '#',
        title: 'idea',
        body: 'Write on the fly.',
        shareLinkToken: '',
        cursorPosition: 0,
      }
    ]
  }
};

export default config;

