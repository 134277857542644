import { render } from "./ModalBase.vue?vue&type=template&id=731083ca&scoped=true"
import script from "./ModalBase.vue?vue&type=script&lang=js"
export * from "./ModalBase.vue?vue&type=script&lang=js"

import "./ModalBase.vue?vue&type=style&index=0&id=731083ca&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-731083ca"
/* custom blocks */
import block0 from "./ModalBase.vue?vue&type=custom&index=0&blockType=memo"
if (typeof block0 === 'function') block0(script)


export default script