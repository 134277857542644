<template>
  <div id="util-bar">
    <div class="func-block delete" @click="deletePage" v-html="Icon.trash"></div>
    <div class="func-block share" @click="openDropdown" v-html="Icon.share"></div>
    <!-- <div class="func-block share" @click="alert">ALT</div> -->
  </div>
  <ShareLinkDropdown :options="this.link" :selectedPage="this.selectedPage" :pages="this.pages" v-bind="$attrs"></ShareLinkDropdown>
</template>

<script>
import ShareLinkDropdown from './ShareLinkDropdown.vue';
import Icon from './../../util/icon.js';

export default {
  name: 'UtilBar',
  components: {
    ShareLinkDropdown,
  },
  props: ['selectedPage', 'pages'],
  data() {
    return {
      sideMenuHidden: false,
      selected: false,
      shareLinkModalMask: false,
      Icon,
      link: {
        show: false,
        x: 0,
        y: 200,
        close: '',
      }
    }
  },
  methods: {
    deletePage() {
      const msg = 'このページを削除します。よろしいですか？\nこの操作は取り消せません。';
      let flag = confirm(msg);
      if (flag) {
        const eventName = 'delete-page';
        const data = {
            eventName,
            page: this.selectedPage,
          }
        this.$emit(eventName, data);
      }
    },
    // async createShareLink() {
    //   // リンク生成済みの場合は処理を中止
    //   {
    //     const token = this.selectedPage.shareLinkToken;
    //     if (token.length > 0) {
    //       this.shareLink = `http://localhost:8010/?token=${token}`;
    //       this.shareLinkModalMask = true;
    //       return;
    //     }
    //   }

    //   const textarea = document.querySelector('.memo-body');
    //   const data = {
    //     pageTitle: this.selectedPage.title,
    //     pageBody: textarea.value,
    //   };
    //   const url = 'http://localhost:9000/.netlify/functions/createShareLink';
    //   const options = {
    //     method: 'POST',
    //       // headers: {
    //         //   'Content-Type': 'application/json'
    //       // },
    //       body: JSON.stringify(data),
    //     };
      
    //   try {
    //     const res = await fetch(url, options)
    //     if (!res.ok) {
    //       throw new Error(`${res.status} ${res.statusText}`);
    //     }
    //     const json = await res.json();
    //     this.shareLink = `http://localhost:8010/?token=${json.data.token}`;
    //     this.shareLinkModalMask = true;

    //     // シェアリンクを保存
    //     const eventName = 'save-share-link';
    //     const emit = {
    //       eventName,
    //       data: {
    //         ...json,
    //       }
    //     };
    //     this.$emit(eventName, emit);

    //   } catch (err) {
    //     this.shareLink = 'リンクを生成できませんでした。';
    //     this.shareLinkModalMask = true;
    //     console.log(err);
    //   }
    // },
    openDropdown(e) {
      e.stopPropagation(); // DropdownModalコンポーネントでdocumentにクリックイベントを付けており、これが発動しないようにするために必要。
      this.link.show = false;
      this.link.show = true;
      this.link.x = e.x;
      this.link.y = e.y;
      this.link.close = this.closeDropdown;
    },
    closeDropdown() {
      this.link.show = false;
    },
    alert() {
      this.$alert('danger', 'OMG!!!!!!!!!!!!')
    }
  }
}
</script>

<style lang="sass" scoped>
// $bar-bg-color: hsl(180, 20%, 40%)
$side-menu-bg-color: hsl(170, 20%, 20%)

#util-bar
  // padding-right: 1rem
  height: 100%
  background-color: #fcfcfc

@for $i from 1 through 2
  .func-block:nth-of-type(#{$i})
    position: absolute
    top: calc(68px * #{$i - 1})
    right: 20px
    display: flex
    align-items: center
    justify-content: center
    margin: 1.5rem 0
    width: 48px
    height: 48px
    border: 1px solid #ccc
    border-radius: 50%
    cursor: pointer
    background-color: hsl(150, 48%, 95%)
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2)
    transition: .15s
    color: #777

    &:hover,
    &.selected
      background-color: hsl(150, 78%, 86%)

.mask
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  background-color: rgba(0, 0, 0, 0.5)

#share-link-modal
  width: 300px
  height: 180px
  border: 3rem
  background-color: #ffffff
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  z-index: 1000

</style>