<template>
  <DropdownBase :options="this.options">
    <div class="container">
      <div class="dropdown-menu" @click="createShareLink">リンクをコピー</div>
      <div class="dropdown-menu" @click="deleteShareLink">共有を解除</div>
    </div>
  </DropdownBase>
</template>

<script>
import DropdownBase from './../common/DropdownBase.vue';
import { copy } from './../../util/dataUtil.js';

export default {
  name: 'ShareLinkDropdown',
  components: {
    DropdownBase,
  },
  props: ['options', 'selectedPage', 'pages'],
  data() {
    return {
      shareLink: '',
    }
  },
  methods: {
    async createShareLink() {
      // リンク生成済みの場合は処理を中止
      // {
      //   const token = this.selectedPage.shareLinkToken;
      //   if (token.length > 0) {
      //     this.shareLink = `http://localhost:8010/?token=${token}`;

      //     if (navigator.clipboard != null && navigator.clipboard.writeText != null) {
      //         navigator.clipboard.writeText(this.shareLink);
      //     } else {
      //         // このブラウザではこの機能は使えません。
      //     }
      //     this.$alert('success', 'Share link copied')
      //     this.options.close();
      //     return;
      //   }
      // }

      const textarea = document.querySelector('.memo-body');
      const data = {
        pageId: this.selectedPage.pageId,
        pageTitle: this.selectedPage.title,
        pageBody: textarea.value,
      };

      const functionUrl = this.$getAppData().functionUrl;
      const url = `${functionUrl}/createShareLink`;
      // const url = 'http://localhost:9000/.netlify/functions/createShareLink';
      const options = {
        method: 'POST',
          // headers: {
            //   'Content-Type': 'application/json'
          // },
        body: JSON.stringify(data),
      };
      
      try {
        const res = await fetch(url, options)
        if (!res.ok) {
          throw new Error(`${res.status} ${res.statusText}`);
        }
        const json = await res.json();
        
        // this.shareLink = `http://localhost:8010/?token=${json.data.token}`;
        this.shareLink = `https://flynote.space/?token=${json.data.token}`;
        copy(this.shareLink);

        // シェアリンクを保存
        const eventName = 'save-share-link';
        const emit = {
          eventName,
          data: {
            ...json,
          }
        };
        this.$emit(eventName, emit);

      } catch (err) {
        this.shareLink = 'リンクを生成できませんでした。';
        console.log(err);
      }
    },
    async deleteShareLink() {
      const msg = 'リンクを削除してよろしいですか？';

      if (confirm(msg)) {

        const data = {
          pageId: this.selectedPage.pageId,
        };

        const functionUrl = this.$getAppData().functionUrl;
        const url = `${functionUrl}/deleteShareLink`;
        const options = {
          method: 'POST',
          body: JSON.stringify(data),
        };

        try {
          const res = await fetch(url, options)
          if (!res.ok) {
            throw new Error(`${res.status} ${res.statusText}`);
          }

          const eventName = 'delete-share-link';
          this.$emit(eventName);
          this.options.close();

        } catch (err) {
          this.shareLink = 'リンクを生成できませんでした。';
          console.log(err);
        }

      }
    },
  },
}

</script>

<style lang="sass" scoped>
$bg-color: hsl(170, 20%, 20%)

.container
  padding: 0.5rem 0
  border-radius: 4px
  color: #fff
  font-size: 0.85rem
  background-color: $bg-color

.dropdown-menu
  padding: 0.4rem 0.7rem
  cursor: pointer
  &:hover
    background-color: lighten($bg-color, 20) 
</style>

<memo>
コンポーネントの呼出時には以下のプロパティを設定
:mask="SampleModalMask"
:closeModal="closeModal" *対応する関数も作成する必要あり。
  closeModal() {
    this.SampleModalMask = false;
  };
:width="" 任意
:height="" 任意
</memo>