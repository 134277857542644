const appMessage = {
  '0001': 'No message id is set.',
  '0002': 'Page name must be unique.',
  '0003': 'Page name must not be blank.',
  '0004': 'Page was renamed.',
  '0005': 'New Page was added.',
  '0006': 'Page was deleted.',
  '0007': 'Page was deleted and a default page was added.',
};

export default appMessage;