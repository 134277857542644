// ユーティリティ関数 ////////////////////////////////////////////
function getMemoArea() {
  const textarea = document.querySelector('.memo-body');
  return textarea;
}

/**
 * メモを行で区切った配列として取得
 */
function getMemoAsList() {
  const textarea = getMemoArea();
  return textarea.value.split('\n');
}

function arrayToString(arr) {
  return arr.join('\n');
}

/**
 * 本ファイル内で使用する保存処理用関数。
 * テキスト本文を保存するために使用する。
 * @param {Object} that vueインスタンス
 */
function save(that) {
  const textarea = getMemoArea()
  const emit = {
    memo: textarea.value,
  }
  that.$emit('save', emit);
}

async function copyLine() {
  // 文字列を選択しているかどうかの判定
  const selection = window.getSelection();
  if (selection.type != 'Caret') {
    return;
  }
  
  const { line } = getOneLine();

  if (navigator.clipboard != null && navigator.clipboard.writeText != null) {
    navigator.clipboard.writeText(line);
  } else {
    // このブラウザではこの機能は使えません。
  }
}

/**
 * カーソルのある行のみを取得する。
 */
function getOneLine() {
  let textList = getMemoAsList();
  const textarea = getMemoArea();
  
  // カーソルのある行の1行手前までの行数をカウント
  let count = 0;
  const beforeText = textarea.value.substr(0, textarea.selectionStart);
  for (let i=0; i < beforeText.length; i++) {
    if (beforeText[i] === '\n') {
      count++
    }
  }

  // countの数が、カーソル行の行番号となる（行番号は0から始まるため。）
  return { 
    line: textList[count],
    index: count,
  }
}

/**
 * テキスト選択したすべての行を取得
 */
function getSelectedLines() {
  const textarea = getMemoArea();
  let textList = getMemoAsList();

  
  // 選択部分より前のテキストに含まれる改行の数をカウント
  let beforeCount = 0;
  const beforeText = textarea.value.substr(0, textarea.selectionStart);
  for (let i=0; i < beforeText.length; i++) {
    if (beforeText[i] === '\n') {
      beforeCount++
    }
  }
  
  // 選択しているテキストに含まれる改行の数をカウント
  let selectedCount = 0;
  const selectedText = textarea.value.substr(textarea.selectionStart, textarea.selectionEnd - textarea.selectionStart);
  for (let i = 0; i < selectedText.length; i++) {
    if (selectedText[i] === '\n') {
      selectedCount++;
    }
  }
  
  // 選択テキストが含まれる行をすべて取得
  const targetSentences = []
  for (let i = beforeCount; i <= beforeCount + selectedCount; i++) {
    targetSentences.push(textList[i]);
  }

  return {
    lines: targetSentences,
    start: beforeCount,
    end: beforeCount + selectedCount
  }
}

// カーソル位置取得
// function getCursorPosition() {
  
// }


// テキスト処理 //////////////////////////////////////////////////////
function inputDate() {
  const date = new Date().toLocaleDateString();
  document.execCommand('insertText', false, date + ' ');
}

function inputTime() {
  const time = new Date().toLocaleTimeString();
  document.execCommand('insertText', false, time + ' ');
}

function insertTab() {
  const tab = '\t';
  document.execCommand('insertText', false, tab);
}

function insertSeparator() {
  const sep = '--------------------------------------------------\n';
  document.execCommand('insertText', false, sep);
}

function insertBullet() {
  const textarea = getMemoArea();
  const textList = getMemoAsList();
  // const { line, index } = getOneLine();
  const mark = '-';
  const { lines, start } = getSelectedLines();
  
  // カーソル位置修正のために使用
  let selectionStart = textarea.selectionStart;
  let selectionEnd = textarea.selectionEnd;
  let count = 0; // bulletの挿入削除の文字数をカウント

  // 行頭マークの挿入または削除
  let i = 0;
  for (i; i < lines.length; i++) {
    const line = lines[i];
    const firstLetter = line.charAt(0);
    let newLine = '';

    if (firstLetter == mark) {
      // 二文字目がスペースかどうかで分岐
      const regex = new RegExp(/[\s]/);
      if (regex.test(line.charAt(1))) {
        // 二文字目がスペース
        newLine = line.substring(2);

        (i == 0) ? selectionStart -= 2 : ''; // 選択開始位置は最初の行のみ考慮
        count -= 2;
      } else {
        newLine = line.substring(1);
        (i == 0) ? selectionStart -= 1 : '';
        count -= 1;
      }
    } else {
      newLine = `- ${line}`;
      (i == 0) ? selectionStart += 2 : '';
      count += 2;
    }
    textList[start + i] = newLine;
  }

  textarea.value = arrayToString(textList);
  save(this);

  textarea.setSelectionRange(selectionStart, selectionEnd += count);

}

function insertHashtag() {
  const textarea = getMemoArea();
  const textList = getMemoAsList();
  // const { line, index } = getOneLine();
  const mark = '#';
  const { lines, start } = getSelectedLines();
  
  // カーソル位置修正のために使用
  let selectionStart = textarea.selectionStart;
  let selectionEnd = textarea.selectionEnd;
  let count = 0; // bulletの挿入削除の文字数をカウント

  // 行頭マークの挿入または削除
  let i = 0;
  for (i; i < lines.length; i++) {
    const line = lines[i];
    const firstLetter = line.charAt(0);
    let newLine = '';

    if (firstLetter == mark) {
      // 二文字目がスペースかどうかで分岐
      const regex = new RegExp(/[\s]/);
      if (regex.test(line.charAt(1))) {
        // 二文字目がスペース
        newLine = line.substring(2);

        (i == 0) ? selectionStart -= 2 : ''; // 選択開始位置は最初の行のみ考慮
        count -= 2;
      } else {
        newLine = line.substring(1);
        (i == 0) ? selectionStart -= 1 : '';
        count -= 1;
      }
    } else {
      newLine = `# ${line}`;
      (i == 0) ? selectionStart += 2 : '';
      count += 2;
    }
    textList[start + i] = newLine;
  }

  textarea.value = arrayToString(textList);
  save(this);

  textarea.setSelectionRange(selectionStart, selectionEnd += count);

}

function fontSizeDown() {
  const memo = getMemoArea();
  const computedStyle = window.getComputedStyle(memo);
  const num = computedStyle.fontSize.split('px')[0];
  memo.style.fontSize = `${parseInt(num) - 1}px`;
  this.changeFontSize(memo.style.fontSize);
}

function fontSizeUp() {
  const memo = getMemoArea();
  const computedStyle = window.getComputedStyle(memo);
  const num = computedStyle.fontSize.split('px')[0];
  memo.style.fontSize = `${parseInt(num) + 1}px`;
  this.changeFontSize(memo.style.fontSize);
}

function lineUp() {
  let textList = getMemoAsList();
  const textarea = getMemoArea();
  
  // 選択部分より前のテキストに含まれる改行の数をカウント
  let beforeCount = 0;
  const beforeText = textarea.value.substr(0, textarea.selectionStart);
  for (let i=0; i < beforeText.length; i++) {
    if (beforeText[i] === '\n') {
      beforeCount++
    }
  }

  // 最初の行にカーソルがある場合は処理を中止。
  if (beforeCount === 0) {
    return;
  }

  // 選択しているテキストに含まれる改行の数をカウント
  let selectedCount = 0;
  const selectedText = textarea.value.substr(textarea.selectionStart, textarea.selectionEnd - textarea.selectionStart);
  for (let i = 0; i < selectedText.length; i++) {
    if (selectedText[i] === '\n') {
      selectedCount++;
    }
  }

  // 選択テキストが含まれる行をすべて取得
  const targetSentences = []
  for (let i = beforeCount; i <= beforeCount + selectedCount; i++) {
    targetSentences.push(textList[i]);
  }

  // カーソル位置の調整（位置取得のみ）
  const cursorPositionStart = textarea.selectionStart - textList[beforeCount - 1].length - 1;
  const cursorPositionEnd = textarea.selectionEnd - textList[beforeCount - 1].length - 1;

  // 文章の削除と挿入
  textList.splice(beforeCount, selectedCount + 1);
  textList.splice(beforeCount - 1, 0, ...targetSentences);
  textarea.value = arrayToString(textList);
  save(this);
  
  // カーソル位置調整
  textarea.setSelectionRange(cursorPositionStart, cursorPositionEnd);
  
}

function lineDown() {
  let textList = getMemoAsList();
  const textarea = getMemoArea();
  
  // 選択部分より前のテキストに含まれる改行の数をカウント
  let beforeCount = 0;
  const beforeText = textarea.value.substr(0, textarea.selectionStart);
  for (let i=0; i < beforeText.length; i++) {
    if (beforeText[i] === '\n') {
      beforeCount++
    }
  }

  // 選択しているテキストに含まれる改行の数をカウント
  let selectedCount = 0;
  const selectedText = textarea.value.substr(textarea.selectionStart, textarea.selectionEnd - textarea.selectionStart);
  for (let i = 0; i < selectedText.length; i++) {
    if (selectedText[i] === '\n') {
      selectedCount++;
    }
  }

  // 最後の行にカーソルがある場合は処理を中止。
  if (beforeCount + selectedCount + 1 === textList.length) {
    return;
  }

  // 選択テキストが含まれる行をすべて取得
  const targetSentences = []
  for (let i = beforeCount; i <= beforeCount + selectedCount; i++) {
    targetSentences.push(textList[i]);
  }


  // カーソル位置の調整（位置取得のみ）
  const cursorPositionStart = textarea.selectionStart + textList[beforeCount + selectedCount + 1].length + 1;
  const cursorPositionEnd = textarea.selectionEnd + textList[beforeCount + selectedCount + 1].length + 1;

  // 文章の削除と挿入
  textList.splice(beforeCount, selectedCount + 1);
  textList.splice(beforeCount + 1, 0, ...targetSentences);
  textarea.value = arrayToString(textList);

  // カーソル位置調整
  textarea.setSelectionRange(cursorPositionStart, cursorPositionEnd);

  save(this);
}



// function createLink(e) {
//   const line = getOneLine();
  
//   console.log(window.getSelection())
//   const regex = new RegExp(/https{0,1}:\/\/.*/);
//   const result = regex.exec(line);
//   if (regex.test(line)) {
//     if (document.querySelector('#test')) {
//       return;
//     }
//     const style = `
//       font-size: 0.8rem;
//       position: absolute;
//       top: ${e.x};
//       left: ${e.y}
//     `;
//     const block = `<a href="${result[0]}" id="test" style="${style}">${result[0]}<a>`;
//     const textarea = getMemoArea();
//     textarea.insertAdjacentHTML('afterend', block);
//   } else {
//     const block = document.querySelector('#test');
//     block.remove();
//   }
// }

function lineHighlight() {
  const textarea = getMemoArea();

  const style = window.getComputedStyle(textarea);
  const paddingTopPx = style.paddingTop;
  let temp = paddingTopPx.indexOf('px');
  const paddingTop = parseInt(paddingTopPx.substring(0, temp)); 

  const fontSizePx = style.fontSize;
  temp = fontSizePx.indexOf('px');
  const fontSize = parseInt(fontSizePx.substring(0, temp));

  const { index } = getOneLine();

  const line = document.querySelector('#line-highlight');

  line.style.top = `${paddingTop + fontSize * 1.1 * index}px`;
  line.style.height = `${fontSize * 1.1}px`;
  console.log(line.style.top)
  console.log(paddingTop + fontSize * index)
}

const InputUtil = {
  inputDate,
  inputTime,
  insertTab,
  insertSeparator,
  insertBullet,
  insertHashtag,
  fontSizeDown,
  fontSizeUp,
  lineUp,
  lineDown,
  copyLine,
  // createLink,
};

export default InputUtil;

export {
  lineHighlight,
}
