import { v4 as uuidv4 } from 'uuid';
import { getAppData, saveAppData } from './dataUtil.js';
import config from './../config.js';


async function myCreated(vm) {
  // 初期状態でメモ欄に何も表示させない処理。
  vm.selectedPage = {
    title: '',
    body: ''
  }

  let appData = getAppData();
  const isNullish = vm.$validation.isBlankText(appData);

  if (isNullish) {
    // appDataがない場合はデフォルトデータを使用
    appData = config.defaultAppData;
    // デフォルトデータのページのpageIdにUUIDを入れる。
    appData.pages[0].pageId = uuidv4();
    vm.pages = appData.pages;
  } else {
    vm.pages = appData.pages;
  }

  // デフォルトデータに新たに追加されたkey, valueを既存のappDataに追加
  // 1階層目まで。
  Object.keys(config.defaultAppData).forEach((key) => {
    const hasKey = Object.keys(appData).some((currentDataKey) => {
      return key == currentDataKey;
    });

    // keyを持っていない場合、この key, value を追加
    if (!hasKey) {
      appData[key] = config.defaultAppData[key];
    }
  })

  // デフォルトデータのconfigに追加されたkey, valueを既存のappDataに追加。
  Object.keys(config.defaultAppData.config).forEach((key) => {
    const hasKey = Object.keys(appData.config).some((currentDataKey) => {
      return key == currentDataKey;
    });

    // keyを持っていない場合、この key, value を追加
    if (!hasKey) {
      appData.config[key] = config.defaultAppData.config[key];
    }
  })

  // デフォルトデータのpages[0]に追加されたkey, valueを既存のappDataに追加。
  const defaultPage = config.defaultAppData.pages[0];
  appData.pages.forEach((page, i) => {
    appData.pages[i] = { ...defaultPage, ...page };
  })

  const { token, json } = await comeFromShareLink();

  setFirstPage();

  saveAppData(appData);

  /**
   * 共有リンクから遷移してきた場合の処理
   */
  async function comeFromShareLink() {
    // 共有リンクから遷移してきた場合の処理
    let token = null;
    let json = null;
    if (window.URL) {
      const sharedLink = new URL(window.location.href);
      token = sharedLink.searchParams.get('token');
    } else {
      const sharedLink = window.location.href;
      const index = sharedLink.indexOf('?');
      if (index != -1) {
        token = sharedLink.slice(index + 1);
      }
    }

    if (token) {
      const functionUrl = vm.$getAppData().functionUrl;
      const url = `${functionUrl}/getSharedPage`;
      const data = {
        token,
      };
      const options = {
        method: 'POST',
        body: JSON.stringify(data),
      }
      
      try {
        const res = await fetch(url, options);
        if (!res.ok) {
          throw new Error(`${res.status} ${res.statusText}`);
        }
        json = await res.json();
      } catch (err) {
        console.log(err);
      }
    }

    return {
      token,
      json,
    }
  }

  /**
   * アプリを開いたとき表示するページ決定の処理
   */
  function setFirstPage() {
    if (token) {
      // 共有リンクから開いた場合は共有されたページ
      const sharedPage = { ...config.defaultAppData.pages[0] };

      // 同じタイトルのページの有無判定
      const hasSame = vm.pages.some((page) => {
        return page.title === json.pageTitle;
      });
      
      if (hasSame) {
        let count = 1;
        let newTitle;
        let stillHasSame = false;
        do {
          newTitle = `${json.pageTitle}_${count}`;
          stillHasSame = vm.pages.some((page)=> {
            return page.title === newTitle;
          });
          count++;
        } while (stillHasSame);

        sharedPage.title = newTitle;
      } else {
        sharedPage.title = json.pageTitle;
      }
      sharedPage.body = json.pageBody;
      sharedPage.pageId = uuidv4();

      appData.selectedPageTitle = sharedPage.title;
      vm.selectedPage = sharedPage;
      vm.pages.push(vm.selectedPage);
    } else {
      // 最後に選択していたページを表示する
      const selectedPage = vm.pages.filter((page) => {
        return page.title == appData.selectedPageTitle;
      })
      vm.selectedPage = selectedPage[0];
    }
  }
}

export {
  myCreated,
}