<template>
  <div id="auto-saved" class="">auto saved</div>
</template>

<script>
export default {
  name: 'AutoSaved',
  mounted() {
    // const pop = document.querySelector('#auto-saved');
    document.addEventListener('keydown', function f(e) {
      if (e.ctrlKey && e.key === 's') {
        e.preventDefault();

        const pop = document.querySelector('#auto-saved');

        // animクラスを持っている場合は処理を中止
        if (pop.classList.contains('anim')) {
          return;
        }

        pop.classList.add('anim');

        // アニメーションが終了したときに animクラスを削除。
        pop.addEventListener('animationend', function f2(e) {
          e.target.classList.remove('anim');
          document.removeEventListener('keydown', f2);
        });
      }
    });
  }
}
</script>

<style lang="sass" scoped>
#auto-saved
  padding: 0.5rem 1rem
  position: absolute
  bottom: 0
  left: 50%
  transform: translateX(-50%)
  border-radius: 30px
  background: hsl(340, 100%, 58%)
  color: #ffffff
  transition: .3s
  opacity: 0
  
.anim
  animation: autosaved 2s
</style>