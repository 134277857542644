<template>
  <div id="logo"></div>
</template>

<script>
// import Logo from './../../assets/fly_note.svg';

export default {
  name: 'Logo',
  data() {
    return {
      // Logo
    }
  }
}
</script>

<style lang="sass" scoped>
#logo
  background-image: url('./../../assets/fly_note.svg');
  background-repeat: no-repeat
  background-position: center
  background-size: 120px
  width: 100%
  height: 40px
</style>