/**
 * タイトル昇順で並び替え
 * @param {*} pages 
 */
function sortPages(pages) {
  // ページタイトルのリストを作成
  const titleList = [];
  pages.forEach((page) => {
    titleList.push(page.title);
  });

  // タイトルリストのソート（元のリスト自体変更される）
  titleList.sort();

  const sortedPages = [];
  titleList.forEach((title) => {
    pages.forEach((page) => {
      if (title == page.title) {
        sortedPages.push(page);
      }
    })
  });

  return sortedPages;
}

export {
  sortPages,
}
