import { createApp } from 'vue'
import App from './App.vue'
import { getAppData } from './util/dataUtil.js'
import appMessage from './util/appMessage.js'
import ValidationRule from './validation/validation_rule.js'
// import config from './config.js'

const app = createApp(App);

// app.config.globalProperties.$appData = getAppData;
app.config.globalProperties.$getAppData = getAppData;
app.config.globalProperties.$alert = appAlert;
app.config.globalProperties.$getAppMessage = getAppMessage;
app.config.globalProperties.$validation = ValidationRule;

app.mount('#app');

function appAlert(type = 'success', msg = 'no message') {
  // アラート表示済みの場合はDOMを削除
  const prevAlert = document.querySelector('.app-alert');
  if (prevAlert != undefined) {
    prevAlert.remove();
  }

  const time = '8s';
  let color = '';
  const s = '78%'
  const l = '84%'
  switch (type) {
    case 'success':
      color = `hsl(130, ${s}, ${l})`
      break;
    case 'danger':
      color = `hsl(0, ${s}, ${l})`
      break;
    default:
      color = `hsl(220, ${s}, ${l})`
      // fontColor = `hsl(220, ${s}, ${fl})`
      break;
  }

  const alert = `
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    border-radius: 0 0 6px 6px;
    padding: 0.8rem 1.5rem;
    background-color: ${color};
    font-size: 1.1rem;
    animation: myAlertShow ${time} ease-in-out;
    animation-fill-mode: forwards;
  `;

  const closeAlert = `
    font-size: 1.4rem;
    cursor: pointer;
  `;
  
  const block = `
    <div class="app-alert" style="${alert}">
      <div style="">${msg}</div>
      <div class="close-alert" style="${closeAlert}">×</div>
    </div>
  `;
  document.body.insertAdjacentHTML('beforeend', block);

  const close = document.querySelector('.close-alert');
  close.addEventListener('click', (e) => {
    e.target.parentElement.remove();
  })
}

function getAppMessage(messageId = '0001') {
  return appMessage[messageId];
}