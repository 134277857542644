<template>
  <div 
    class="page-title" 
    tabindex="0"
    :data-page-title="this.page.title"
    @mousedown="selectPage" 
    @keydown.exact.f2.prevent="renameMode"
    :class="(this.selectedPage.title == this.page.title) ? 'selected' : ''"
  >{{ this.page.title }}</div>
  <div class="sub-page-container"></div>
  <!-- <div class="separator"
    @dragover.prevent
    @dragenter.prevent="dragEnter"
    @dragleave.prevent="dragLeave"
    @drop="changeOrder"
  ></div> -->
</template>

<script>
// import SubPageBlock from './SubPageBlock.vue';
import ValidationRule from './../../validation/validation_rule.js';
import Icon from './../../util/icon.js';

export default {
  name: 'PageBlock',
  props: ['pages', 'selectedPage', 'page'],
  emits: [
    'select-page', 'complete-rename',
    'selectPage', 'completeRename', 'deletePage',
  ],
  components: {
    // SubPageBlock,
  },
  data() {
    return {
      count: 0,
      Icon,
    }
  },
  methods: {
    selectPage(e) {
      const data = {
        selectedPage: this.page,
      }
      this.$emit('select-page', data);

      // クリックした要素のみdraggableにし、それ以外はfalseにする。
      const titleList = document.querySelectorAll('.page-title');
      titleList.forEach((title) => {
        title.draggable = false;
      })
      e.target.draggable = true;

    },
    renameMode(e) {
      const target = e.target;
      target.contentEditable = true;

      // キャレット位置の調整
      const range = document.createRange();
      range.setStart(target.childNodes[0], 0);
      range.setEnd(target.childNodes[0], target.childNodes[0].length);
      range.collapse();
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      const that = this;


      // enterボタンで確定
      target.removeEventListener('keydown', completeRename);
      target.addEventListener('keydown', completeRename);
      function completeRename(e) {
        if (e.key != 'Enter') {
          return;
        }

        if (e.target.contentEditable === 'true') {
          const text = e.target.textContent;

          // 空文字は登録させない
          // 同一のページ名は登録させない。
          const isBlankText = ValidationRule.isBlankText(text);
          const hasSamePageTitle = ValidationRule.hasSamePageTitle(that.pages, text, e.target.dataset.pageTitle);
          if (isBlankText) {
            e.preventDefault();
            that.$alert('warning', that.$getAppMessage('0003'));
            return;
          } else if (hasSamePageTitle) {
            e.preventDefault();
            that.$alert('warning', that.$getAppMessage('0002'));
            return;
          }

          const data = {
            name: e.target.textContent,
          };
          that.$emit('complete-rename', data);

          that.$alert('success', that.$getAppMessage('0004'));

          e.target.contentEditable = false;
          e.target.click();

          e.target.removeEventListener('keydown', completeRename);
          e.target.removeEventListener('keydown', cancelRenameMode);
          document.removeEventListener('mousedown', cancelOrComplete);
        }
      }

      // escキーを押したら編集モードを解除
      target.removeEventListener('keydown', cancelRenameMode);
      target.addEventListener('keydown', cancelRenameMode);

      function cancelRenameMode(e) {
        if (e.key == 'Esc' || e.key == 'Escape') {
          e.target.textContent = e.target.dataset.pageTitle;
          e.target.contentEditable = false;
          e.target.removeEventListener('keydown', completeRename);
          e.target.removeEventListener('keydown', cancelRenameMode);
          document.removeEventListener('mousedown', cancelOrComplete);
        }
      }
      
      // 編集モードでインプットエリア外をクリックしたら確定。
      document.removeEventListener('mousedown', cancelOrComplete);
      document.addEventListener('mousedown', cancelOrComplete);
      function cancelOrComplete(e) {
        const hasSamePageTitle = ValidationRule.hasSamePageTitle(that.pages, target.textContent);

        // インプットエリア外をクリックした場合
        // target はテキスト入力エリア
        // e.target はそれ以外のエリア
        if (e.target !== target) {
          if (target.textContent == '' || hasSamePageTitle) {
            target.textContent = target.dataset.pageTitle;
            target.contentEditable = false;
          } else {
            target.contentEditable = false;
            const data = {
              name: target.textContent,
            };
            that.$emit('complete-rename', data);
            that.$alert('success', that.$getAppMessage('0004'));
          }
          target.removeEventListener('keydown', completeRename);
          target.removeEventListener('keydown', cancelRenameMode);
          document.removeEventListener('mousedown', cancelOrComplete);
        }
      }
    },
    // completeRename(e) {
    //   if (e.target.contentEditable === 'true') {
    //     const text = e.target.textContent;

    //     // 空文字は登録させない
    //     // 同一のページ名は登録させない。
    //     const isBlankText = ValidationRule.isBlankText(text);
    //     const hasSamePageTitle = ValidationRule.hasSamePageTitle(this.pages, text, e.target.dataset.pageTitle);
    //     if (isBlankText || hasSamePageTitle) {
    //       return;
    //     }

    //     const data = {
    //       name: e.target.textContent,
    //     };
    //     this.$emit('complete-rename', data);

    //     e.target.contentEditable = false;
    //     e.target.click();
    //   }
    // },
    dragEnter(e) {
      e.target.classList.add('dragenter');
    },
    dragLeave(e) {
      e.target.classList.remove('dragenter')
    },
    changeOrder(e) {
      e.target.classList.remove('dragenter')
      console.log(this.page.title)
      console.log(e)
    },
    selectPageUp() {
      let index = null;
      this.pages.forEach((page, i) => {
        if (page.title == this.selectedPage.title) {
          index = i;
        }
      });

      // 最初のページを選択中のときは、処理を中止。
      if (index == 0) {
        return;
      }
      
      const emit = {
        eventName: 'select-page-up',
        data: {
          pageNum: index,
        }
      };
      this.$emit('select-page-up', emit);
    },
    selectPageDown() {
      const eventName = 'select-page-down';
      let index = null;
      this.pages.forEach((page, i) => {
        if (page.title == this.selectedPage.title) {
          index = i;
        }
      });

      // 最後のページを選択中のときは、処理を中止。
      if (index == this.pages.length - 1) {
        return;
      }
      
      const emit = {
        eventName,
        data: {
          pageNum: index,
        }
      };
      this.$emit(eventName, emit);
    },
    deletePage(e) {
      // リネーム中は頁削除処理を中止
      if (e.target.contentEditable == 'true') {
        return;
      }
      const msg = 'このページを削除します。よろしいですか？\nこの操作は取り消せません。';
      let flag = confirm(msg);
      if (flag) {
        const eventName = 'delete-page';
        const data = {
            eventName,
            page: this.selectedPage,
          }
        this.$emit(eventName, data);
      }
    },
  },
  mounted() {
    const titleList = document.querySelectorAll('.page-title');
    
    // すべてのコンポーネントが最初にマウントされるタイミングでのみイベントをつける。
    if (this.count == 0) {
      titleList.forEach((node) => {
        node.addEventListener('keydonw', (e) => {
          if(e.key == 'Delete') {
            this.deletePage(e);
          }
        })
      })
    }

    this.count++;
  }
}

</script>

<style lang="sass" scoped>
.page-title
  flex: 1
  padding: 0.15rem 0.5rem
  line-height: 1.5
  outline: none
  font-size: 0.96rem
  cursor: default

  &:hover:not(.selected)
    background-color: rgba(255, 255, 255, 0.1)

  &:focus
    border: 1px solid hsl(200, 100%, 58%)

  &[contenteditable=true]
    background-color: hsl(200, 100%, 60%)

.selected
  background-color: rgba(255, 255, 255, 0.25)

.separator
 height: 4px
 &.dragenter
   background-color: yellow 
</style>