import config from './../config.js';
import ValidationRule from './../validation/validation_rule.js';

function getAppData() {
  let appData = localStorage.getItem(config.sessionKey);
  appData = JSON.parse(appData);

  if (ValidationRule.isBlankText(appData)) {
    return config.defaultAppData;
  } else {
    return appData;
  }
}

function saveAppData(appData) {
  // that.$appData = appData;
  localStorage.setItem(config.sessionKey, JSON.stringify(appData));
}

function saveSelectedPageData(that, selectedPageDataObj) {
  const appData = getAppData();

  appData.pages.forEach((page, i) => {
    if (page.title == that.selectedPage.title) {
      appData.pages[i] = { ...page, ...selectedPageDataObj};
    }
  })
  saveAppData(appData);
}

function copy(text) {
  if (navigator.clipboard != null && navigator.clipboard.writeText != null) {
    navigator.clipboard.writeText(text);
  } else {
    const block = `<input id="input" type="hidden" value="${text}">`;
    document.body.insertAdjacentElement('beforeend', block);

    const input = document.querySelector('#input');
    input.select();
    document.execCommand('copy');
    input.remove();
  }
}

export {
  getAppData,
  saveAppData,
  saveSelectedPageData,
  copy,
}