<template>
  <SideMenu
    :selected-page="selectedPage"
    :pages="pages" 
    @add-page="addPage" 
    @select-page="selectPage"
    @complete-rename="completeRename"
    @select-page-up="selectPageUp"
    @select-page-down="selectPageDown"
    @delete-page="deletePage"
   />
  <div class="wrapper-flex-column">
    <div class="wrapper">
      <Main
        :selected-page="selectedPage"
        :pages="pages"
        :change-font-size="changeFontSize"
        @save="save"
        @page-up="pageUp"
        @page-Down="pageDown"
      />
      <UtilBar 
        :selected-page="selectedPage"
        :pages="pages"
        @delete-page="deletePage"
        @clear-page="clearPage"
        @save-share-link="saveShareLink"
        @delete-share-link="deleteShareLink"
      />
    </div>
    <Footer/>
  </div>
  <ModalBase></ModalBase>
</template>

<script>
// import app from './main.js';
import SideMenu from './components/left/SideMenu.vue';
import Main from './components/center/Main.vue';
import UtilBar from './components/right/UtilBar.vue';
import ModalBase from './components/common/ModalBase.vue';
import Footer from './components/footer/Footer.vue';
import config from './config.js';
// import ValidationRule from './validation/validation_rule.js'
import { getAppData, saveSelectedPageData } from './util/dataUtil.js';
import { saveAppData } from './util/dataUtil.js';
import { sortPages } from './util/sortUtil.js';
import { v4 as uuidv4 } from 'uuid';
import { myCreated } from './util/appvue.js';

export default {
  name: 'App',
  components: {
    SideMenu,
    Main,
    UtilBar,
    ModalBase,
    Footer,
  },
  data() {
    return {
      selectedPage: {},
      pages: [],
      mask: false,
    }
  },
  methods: {
    addPage(emit) {
      const newPage = emit;
      this.selectedPage = newPage;
      this.pages.push(newPage);

      // this.pagesのソート
      this.pages = sortPages(this.pages);

      const appData = getAppData();
      appData.pages = this.pages;
      appData.selectedPageTitle = this.selectedPage.title;
      saveAppData(appData);
    },
    selectPage(emit) {
      this.selectedPage = emit.selectedPage;
      const appData = getAppData();
      appData.selectedPageTitle = this.selectedPage.title;
      saveAppData(appData);
    },
    save(emit) {
      const appData = getAppData();

      appData.pages.forEach((page) => {
        if (page.title == this.selectedPage.title) {
          page.body = emit.memo;
        }
      });

      this.selectedPage.body = emit.memo;

      saveAppData(appData);
    },
    deletePage(emit) {
      const pageToDelete = emit.page;
      let pageNum = null; // 削除するページのページ番号

      let appData = getAppData();

      // 削除するページのタイトルに合致しないもののみ残す（合致したものはフィルタを通らない）
      appData.pages = appData.pages.filter((page, i) => {
        if (page.title == pageToDelete.title) {
          pageNum = i;
        }
        return page.title != pageToDelete.title;
      });

      let defaultPageAdded = false; // アラート表示内容を決めるためのフラグ
      if (appData.pages.length === 0) {
        appData.pages.push(...config.defaultAppData.pages);
        appData.pages[0].pageId = uuidv4();
        defaultPageAdded = true;
      }

      saveAppData(appData);

      this.pages = appData.pages;

      // 選択中ページの設定
      if (pageNum === null || pageNum === 0) {
        pageNum = 0;
      } else {
        pageNum -= 1;
      }
      this.selectedPage = appData.pages[pageNum];

      appData = getAppData();
      appData.selectedPageTitle = this.selectedPage.title;
      saveAppData(appData);

      // アラートの設定
      if (defaultPageAdded) {
        this.$alert('success', this.$getAppMessage('0007'));
      } else {
        this.$alert('success', this.$getAppMessage('0006'));
      }
    },
    clearPage() {
      const appData = getAppData();

      appData.pages.forEach((page) => {
        if (page.title == this.selectedPage.title) {
          page.body = '';
        }
      });
      const textarea = document.querySelector('.memo-body');
      textarea.value = '';
      this.selectedPage.body = '';

      saveAppData(appData);
    },
    completeRename(emit) {
      let appData = getAppData();
      appData.pages.forEach((page) => {
        if (page.title == this.selectedPage.title) {
          page.title = emit.name;
          this.selectedPage.title = emit.name;
        }
      });

      // this.pagesのソート
      this.pages = sortPages(appData.pages);

      appData.pages = this.pages;
      appData.selectedPageTitle = this.selectedPage.title;
      saveAppData(appData);
    },
    selectPageUp(emit) {
      this.selectedPage = this.pages[emit.data.pageNum - 1]
      const appData = getAppData();
      appData.selectedPageTitle = this.selectedPage.title;
      saveAppData(appData);
    },
    selectPageDown(emit) {
      this.selectedPage = this.pages[emit.data.pageNum + 1]
      const appData = getAppData();
      appData.selectedPageTitle = this.selectedPage.title;
      saveAppData(appData);
    },
    pageUp(emit) {
      this.selectedPage = this.pages[emit.data.pageNum - 1]
      const appData = getAppData();
      appData.selectedPageTitle = this.selectedPage.title;
      saveAppData(appData);

      // テキストエリアにフォーカス
      const textarea = document.querySelector('.memo-body');
      textarea.focus();
    },
    pageDown(emit) {
      this.selectedPage = this.pages[emit.data.pageNum + 1]
      const appData = getAppData();
      appData.selectedPageTitle = this.selectedPage.title;
      saveAppData(appData);

      // テキストエリアにフォーカス
      const textarea = document.querySelector('.memo-body');
      textarea.focus();
    },
    changeFontSize(size) {
      const appData = getAppData();
      const fontSizeObj = { fontSize: size };
      appData.config = { ...appData.config, ...fontSizeObj };
      saveAppData(appData);
    },
    saveShareLink(emit) {
      // const appData = getAppData();
      // appData.pages.forEach((page) => {
      //   if (page.title == emit.data.data.pageTitle) {
      //     page.shareLinkToken = emit.data.data.token;
      //   }
      // });
      const newData = {
        shareLinkToken: emit.data.data.token,
      }
      // saveAppData(appData);
      saveSelectedPageData(this, newData);
      this.selectedPage.shareLinkToken = newData.shareLinkToken;
      this.$alert('success', 'Share link copied')
    },
    deleteShareLink() {
      const newData = {
        shareLinkToken: '',
      };
      saveSelectedPageData(this, newData);
      this.selectedPage.shareLinkToken = newData.shareLinkToken;
      this.$alert('success', 'Sharing stopped.')
    }
  },
  async created() {
    myCreated(this);
    
    // // 共有リンクから遷移してきた場合の処理
    // let token = null;
    // let json = null;
    // if (URL) {
    //   const sharedLink = new URL(window.location.href);
    //   token = sharedLink.searchParams.get('token');
    // } else {
    //   const sharedLink = window.location.href;
    //   const index = sharedLink.indexOf('?');
    //   if (index != -1) {
    //     token = sharedLink.slice(index + 1);
    //   }
    // }

    // if (token) {
    //   const functionUrl = this.$appData.functionUrl;
    //   const url = `${functionUrl}/getSharedPage`;
    //   const data = {
    //     token,
    //   };
    //   const options = {
    //     method: 'POST',
    //     body: JSON.stringify(data),
    //   }
      
    //   try {
    //     const res = await fetch(url, options);
    //     if (!res.ok) {
    //       throw new Error(`${res.status} ${res.statusText}`);
    //     }
    //     json = await res.json();
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }


    // // アプリを開いたとき表示するページ決定の処理
    // if (token) {
    //   // 共有リンクから開いた場合は共有されたページ
    //   const sharedPage = { ...config.defaultAppData.pages[0] };

    //   // 同じタイトルのページの有無判定
    //   const hasSame = this.pages.some((page) => {
    //     return page.title === json.pageTitle;
    //   });
      
    //   if (hasSame) {
    //     let count = 1;
    //     let newTitle;
    //     let stillHasSame = false;
    //     do {
    //       newTitle = `${json.pageTitle}_${count}`;
    //       stillHasSame = this.pages.some((page)=> {
    //         return page.title === newTitle;
    //       });
    //       count++;
    //     } while (stillHasSame);

    //     sharedPage.title = newTitle;
    //   } else {
    //     sharedPage.title = json.pageTitle;
    //   }
    //   sharedPage.body = json.pageBody;
    //   sharedPage.pageId = uuidv4();

    //   appData.selectedPageTitle = json.pageTitle;
    //   this.selectedPage = sharedPage;
    //   this.pages.push(this.selectedPage);
    // } else {
    //   // 最後に選択していたページを表示する
    //   const selectedPage = this.pages.filter((page) => {
    //     return page.title == appData.selectedPageTitle;
    //   })
    //   this.selectedPage = selectedPage[0];
    // }

    // saveAppData(appData);
  },
}

</script>

<style lang="sass">
html,
body,
div,
p,
h1,h2,h3,h4,h5,h6
  margin: 0
  padding: 0

#app
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex
  height: 100vh
  min-height: 540px
  font-size: 16px
  line-height: 1.5

.wrapper-flex-column
  flex: 1
  display: flex
  flex-direction: column

.wrapper
  flex: 1
  display: flex

// alertのスタイル（一部）
.close-alert
  &:hover
    color: #999
</style>
