import { render } from "./DropdownBase.vue?vue&type=template&id=1b98a4f2&scoped=true"
import script from "./DropdownBase.vue?vue&type=script&lang=js"
export * from "./DropdownBase.vue?vue&type=script&lang=js"

import "./DropdownBase.vue?vue&type=style&index=0&id=1b98a4f2&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-1b98a4f2"
/* custom blocks */
import block0 from "./DropdownBase.vue?vue&type=custom&index=0&blockType=memo"
if (typeof block0 === 'function') block0(script)


export default script