<template>
  <div id="side-menu" :style="sideMenuStyle">
    <div id="app-name">
      <Logo />
    </div>
    <div class="btn-area">
      <div id="add-page" @click="addPage">+</div>
    </div>
    <div id="pages-container">
      <PageBlock v-for="page in this.pages" :key="page" :pages="this.pages" :page="page" v-bind="$attrs" :selectedPage="this.selectedPage"/>
      <input type="text" class="page-title-input" tabindex="0" v-show="pageTitleInput"/>
    </div>
    <div class="ads-area">
      <a href="https://px.a8.net/svt/ejp?a8mat=3BSW9B+FOEY9E+50+2HCB1D" rel="nofollow">
<img border="0" width="100" height="60" alt="" src="https://www20.a8.net/svt/bgt?aid=201222479948&wid=001&eno=01&mid=s00000000018015006000&mc=1"></a>
<img border="0" width="1" height="1" src="https://www16.a8.net/0.gif?a8mat=3BSW9B+FOEY9E+50+2HCB1D" alt="">
    </div>
  </div>
  <!-- <div id="slider"
  ></div> -->
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import PageBlock from './PageBlock.vue';
import Logo from './Logo.vue';
import ValidationRule from './../../validation/validation_rule.js';

export default {
  name: 'SideMenu',
  components: {
    PageBlock,
    Logo,
  },
  props: ['selectedPage', 'pages'],
  emits: ['add-page', 'select-page', 'complete-rename'],
  data() {
    return {
      sideMenuStyle: {
        width: '200px',
      },
      holdeSlide: false,
      pageTitleInput: false,
    }
  },
  methods: {
    addPage() {
      if (this.pageTitleInput) {
        // 既にページ追加モードのときは処理を中止
        const input = document.querySelector('.page-title-input');
        input.focus();
        return;
      } else {
        this.pageTitleInput = true;
      }

      const input = document.querySelector('.page-title-input');

      // なぜか非同期処理しないとフォーカスがあたらない。。
      setTimeout(() => input.focus(), 0)

      // イベント付与
      input.addEventListener('keydown', (e) => {
        if (e.keyCode == 13) {
          completeRename(this, e);
        }
      })

      input.addEventListener('keydown', (e) => {
        if (e.key == 'Esc' || e.key == 'Escape') {
          e.target.value = '';
          this.pageTitleInput = false;
          document.removeEventListener('mousedown', cancelOrComplete);
        }
      });

      // リネームエリア以外をクリックした場合の処理
      document.addEventListener('mousedown', cancelOrComplete);

      const that = this;
      function cancelOrComplete(e) {
        const hasSamePageTitle = ValidationRule.hasSamePageTitle(that.pages, input.value);

        if (e.target !== input) {
          if (input.value == '' || hasSamePageTitle) {
            input.value = '';
            that.pageTitleInput = false;
            document.removeEventListener('mousedown', cancelOrComplete);
          } else {
            completeRename(that, e, input);
          }
        }
      }

      function completeRename(that, e, input = null) {
        let newTitle;
        if (input) {
          newTitle = input.value.trim();
        } else {
          newTitle = e.target.value.trim();
        }

        // 空文字は登録させない
        // 同一のページ名は登録させない。
        const isBlankText = ValidationRule.isBlankText(newTitle);
        const hasSamePageTitle = ValidationRule.hasSamePageTitle(that.pages, newTitle);
        if (isBlankText || hasSamePageTitle) {
          return;
        }

        const newPage = {
          pageId: uuidv4(),
          parentPageId: '#',
          title: newTitle,
          body: 'New Page',
          shareLinkToken: '',
          cursorPosition: 0,
        };
        that.$emit('add-page', newPage);
        if (input) {
          input.value = '';          
        } else {
          e.target.value = '';
        }
        that.pageTitleInput = false;
        document.removeEventListener('mousedown', cancelOrComplete);

        that.$alert('success', that.$getAppMessage('0005'))
      }
    },
    addSubPage() {
      const container = document.querySelector('#pages-container');
      const html = `<input type="text" class="page-title-input" />`;
      
      const pageTitleInput = document.querySelectorAll('.page-title-input');

      // 既にページ追加モードのときは処理を中止
      if (pageTitleInput.length > 0) {
        // pageTitleInput.forEach((input) => {
        //   input.classList.add('warning-input');
        // })
        return;
      }
      container.insertAdjacentHTML('beforeend', html);
      const input = document.querySelector('.page-title-input');
      input.focus();

      // イベント付与
      input.addEventListener('keydown', (e) => {
        if (e.keyCode == 13) {
          const newTitle = e.target.value.trim();

          // 空文字は登録させない
          const isBlankText = ValidationRule.isBlankText(newTitle);
          if (isBlankText) {
            return;
          }

          // 同一のファイル名は登録させない
          let hasSameTitle = false;
          this.pages.forEach((page) => {
            if (page.title == newTitle) {
              hasSameTitle = true;
            }
          });
          if (hasSameTitle) {
            return;
          }

          const newPage = {
            title: newTitle,
            body: 'New Page',
            subpages: [],
          };
          this.$emit('add-sub-page', newPage);
          e.target.remove();
        }
      })

      input.addEventListener('keydown', (e) => {
        if (e.key == 'Esc' || e.key == 'Escape') {
          e.target.remove();
        }
      })
    },
    // startSlide() {
    //   this.holdSlide = true;
    // },
    // resizeSlide(e) {
    //   if (this.holdSlide) {
    //     console.log(e.x)
    //     this.sideMenuStyle.width = e.x + 'px';
    //   }
    // },
    // stopSlide() {
    //   this.holdeSlide = false;
    // },
  },
  mounted() {
    document.addEventListener('keydown', (e) => {
      if (e.ctrlKey && e.key == 'b') {

        const btnArea = document.querySelector('.btn-area');
        const pagesContainer = document.querySelector('#pages-container');
        const adsArea = document.querySelector('.ads-area');

        [btnArea, pagesContainer, adsArea].forEach((el) => {
          if (el.classList.contains('hidden')) {
            el.classList.remove('hidden');
          } else {
            el.classList.add('hidden');
          }
        });
      }
    })
  }
}
</script>

<style lang="sass" scoped>
$side-menu-bg-color: hsl(170, 20%, 20%)
// $side-menu-bg-color: hsl(170, 10%, 93%)

#side-menu
  display: flex
  flex-direction: column
  height: 100%
  background-color: $side-menu-bg-color
  color: #fff

#app-name
  display: flex
  align-items: center
  justify-content: center
  margin-top: 0.6rem
  padding: 0.3rem
  text-align: center
  font-size: 1.6rem

#add-page
  margin: 1rem .5rem
  border: 1px solid #ffffff
  border-radius: 2px
  font-size: 1.5rem
  text-align: center
  cursor: pointer
  transition: .15s
  line-height: 1.4

  &:hover
    background-color: hsl(190, 90%, 92%)
    color: $side-menu-bg-color

#slider
  width: 4px
  background-color: $side-menu-bg-color
  border: none
  cursor: e-resize

.page-title-input
  margin-left: 0.2rem
  padding: 0 0.3rem
  width: 90%
  line-height: 1.5
  font-size: 1rem
  outline: none

.hidden
  transform: translateX(-500px);

#pages-container
  flex: 1
  overflow-y: scroll
  transition: .15s

/*スクロールバー全体*/
::-webkit-scrollbar
  width: 6px

// /*スクロールバーの軌道*/
::-webkit-scrollbar-track 
  border-radius: 6px

// /*スクロールバーの動く部分*/
::-webkit-scrollbar-thumb 
  background-color: lighten($side-menu-bg-color, 20)
  border-radius: 6px

.ads-area,
.btn-area
  transition: .15s

.ads-area
  display: flex
  align-items: center
  justify-content: center
  margin: 1rem
  // height: 200px
  // background-color: rgba(0, 0, 0, 0.4)
</style>