const trash = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="delete_24px">
<path id="icon/action/delete_24px" fill-rule="evenodd" clip-rule="evenodd" d="M14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM16 9V19H8V9H16ZM6 7H18V19C18 20.1 17.1 21 16 21H8C6.9 21 6 20.1 6 19V7Z" fill="black" fill-opacity="0.54"/>
</g>
</svg>`;

const share = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="share_24px">
<path id="icon/social/share_24px" fill-rule="evenodd" clip-rule="evenodd" d="M16.04 16.89C16.56 16.42 17.24 16.12 18 16.12C19.61 16.12 20.92 17.43 20.92 19.04C20.92 20.65 19.61 21.96 18 21.96C16.39 21.96 15.08 20.65 15.08 19.04C15.08 18.82 15.11 18.6 15.16 18.39L8.04 14.23C7.5 14.73 6.79 15.04 6 15.04C4.34 15.04 3 13.7 3 12.04C3 10.38 4.34 9.04001 6 9.04001C6.79 9.04001 7.5 9.35001 8.04 9.85001L15.09 5.74001C15.04 5.51001 15 5.28001 15 5.04001C15 3.38001 16.34 2.04001 18 2.04001C19.66 2.04001 21 3.38001 21 5.04001C21 6.70001 19.66 8.04001 18 8.04001C17.21 8.04001 16.5 7.73001 15.96 7.23001L8.91 11.34C8.96 11.57 9 11.8 9 12.04C9 12.28 8.96 12.51 8.91 12.74L16.04 16.89ZM19 5.04001C19 4.49001 18.55 4.04001 18 4.04001C17.45 4.04001 17 4.49001 17 5.04001C17 5.59001 17.45 6.04001 18 6.04001C18.55 6.04001 19 5.59001 19 5.04001ZM6 13.04C5.45 13.04 5 12.59 5 12.04C5 11.49 5.45 11.04 6 11.04C6.55 11.04 7 11.49 7 12.04C7 12.59 6.55 13.04 6 13.04ZM17 19.06C17 19.61 17.45 20.06 18 20.06C18.55 20.06 19 19.61 19 19.06C19 18.51 18.55 18.06 18 18.06C17.45 18.06 17 18.51 17 19.06Z" fill="black" fill-opacity="0.54"/>
</g>
</svg>
`;

const ctrl = `<svg width="36" height="22" viewBox="0 0 36 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="36" height="22" rx="1" fill="white"/>
<rect x="0.5" y="0.5" width="35" height="21" rx="0.5" stroke="black" stroke-opacity="0.61"/>
<path d="M9.06401 16.1601C8.12535 16.1601 7.32001 15.8135 6.64801 15.1201C5.97601 14.4268 5.64001 13.4881 5.64001 12.3041C5.64001 11.1095 5.94401 10.1388 6.55201 9.39212C7.17068 8.64546 8.04535 8.27212 9.17601 8.27212C9.88001 8.27212 10.4933 8.38946 11.016 8.62412C11.5387 8.84812 11.8 9.16279 11.8 9.56812C11.8 9.93079 11.6027 10.1121 11.208 10.1121C11.0053 10.1121 10.8347 10.0321 10.696 9.87212C10.568 9.71212 10.4667 9.54145 10.392 9.36012C10.328 9.16812 10.1947 8.99212 9.99201 8.83212C9.78935 8.67212 9.52268 8.59212 9.19201 8.59212C8.42401 8.59212 7.84268 8.91212 7.44801 9.55212C7.05335 10.1921 6.85601 11.0721 6.85601 12.1921C6.85601 13.3015 7.09068 14.1655 7.56001 14.7841C8.02935 15.3921 8.61068 15.6961 9.30401 15.6961C10.36 15.6961 11.112 15.2695 11.56 14.4161L11.8 14.5441C11.256 15.6215 10.344 16.1601 9.06401 16.1601Z" fill="black" fill-opacity="0.61"/>
<path d="M15.4991 8.43212H17.4671V8.78412H15.4991V14.8801C15.4991 15.1041 15.5525 15.2855 15.6591 15.4241C15.7658 15.5521 15.9045 15.6161 16.0751 15.6161C16.4591 15.6161 16.9125 15.5041 17.4351 15.2801L17.4671 15.4561C16.7205 15.9255 16.0591 16.1601 15.4831 16.1601C15.1098 16.1601 14.8325 16.0695 14.6511 15.8881C14.4698 15.7068 14.3791 15.3921 14.3791 14.9441V8.78412H13.4191V8.43212C13.9951 8.43212 14.3951 8.27212 14.6191 7.95212C14.8538 7.62145 15.0298 7.13612 15.1471 6.49612L15.4991 6.46412V8.43212Z" fill="black" fill-opacity="0.61"/>
<path d="M23.8405 8.19212C24.1072 8.19212 24.3418 8.25079 24.5445 8.36812C24.7472 8.48546 24.8485 8.64546 24.8485 8.84812C24.8485 9.05079 24.7845 9.21079 24.6565 9.32812C24.5285 9.44546 24.3845 9.50412 24.2245 9.50412C24.0645 9.50412 23.8618 9.43479 23.6165 9.29612C23.3818 9.15746 23.1312 9.08812 22.8645 9.08812C22.4485 9.08812 22.1072 9.47212 21.8405 10.2401C21.5845 11.0081 21.4565 11.8988 21.4565 12.9121C21.4565 14.2775 21.5258 15.1041 21.6645 15.3921C21.7072 15.4881 21.7872 15.5788 21.9045 15.6641C22.0218 15.7388 22.1445 15.7815 22.2725 15.7921C22.4005 15.8028 22.5818 15.8081 22.8165 15.8081L22.8485 16.0001H18.9605L18.9925 15.8081C19.3338 15.8081 19.5792 15.7921 19.7285 15.7601C19.8885 15.7175 20.0218 15.5841 20.1285 15.3601C20.2352 15.1361 20.2938 14.8588 20.3045 14.5281C20.3258 14.1868 20.3365 13.6481 20.3365 12.9121V11.9841C20.3365 10.5015 20.2938 9.65879 20.2085 9.45612C20.1338 9.25345 20.0645 9.09345 20.0005 8.97612C19.9365 8.85879 19.8405 8.77879 19.7125 8.73612C19.4778 8.67212 19.1685 8.64012 18.7845 8.64012L18.7525 8.46412C19.6592 8.33612 20.5605 8.27212 21.4565 8.27212V10.6241C21.5738 9.86679 21.8352 9.27479 22.2405 8.84812C22.6565 8.41079 23.1898 8.19212 23.8405 8.19212Z" fill="black" fill-opacity="0.61"/>
<path d="M28.2586 4.22412V13.2161C28.2586 14.4001 28.328 15.1308 28.4666 15.4081C28.6053 15.6748 28.9893 15.8081 29.6186 15.8081L29.6506 16.0001H25.7306L25.7626 15.8081C26.4026 15.8081 26.792 15.6748 26.9306 15.4081C27.0693 15.1415 27.1386 14.4108 27.1386 13.2161V7.10412C27.1386 5.92012 27.0746 5.20545 26.9466 4.96012C26.904 4.88545 26.824 4.81079 26.7066 4.73612C26.5893 4.66145 26.4613 4.61879 26.3226 4.60812C26.1946 4.59745 26.008 4.59212 25.7626 4.59212L25.7306 4.41612C26.5306 4.28812 27.3733 4.22412 28.2586 4.22412Z" fill="black" fill-opacity="0.61"/>
</svg>
`;

const alt = `<svg width="36" height="22" viewBox="0 0 36 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="36" height="22" rx="5" fill="white"/>
<rect x="0.5" y="0.5" width="35" height="21" rx="4.5" stroke="black" stroke-opacity="1"/>
<path d="M13.8221 12.7402L14.4982 15H16.4377L13.911 6.81496H11.5801L9.08896 15H10.21L10.8861 12.7402H13.8221ZM13.5374 11.7794H11.1708L12.363 7.8114L13.5374 11.7794Z" fill="black" fill-opacity="1"/>
<path d="M20.0181 15V6.37012L18.0253 6.61923V15H20.0181Z" fill="black" fill-opacity="1"/>
<path d="M25.8376 13.879C25.5529 14.0095 25.3157 14.0747 25.1259 14.0747C24.8175 14.0747 24.6633 13.8672 24.6633 13.452V9.60855H25.8732V8.59432H24.6633V7.04627L22.6704 7.72243V8.59432H21.7095V9.60855H22.6704V13.0605C22.6704 13.8553 22.8127 14.395 23.0974 14.6797C23.4177 15 23.8744 15.1602 24.4675 15.1602C25.0725 15.1602 25.6182 15.0297 26.1045 14.7687L25.8376 13.879Z" fill="black" fill-opacity="1"/>
</svg>
`;

const link = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="link_24px">
<path id="icon/content/link_24px" fill-rule="evenodd" clip-rule="evenodd" d="M11 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15ZM17 7H13V9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM16 11H8V13H16V11Z" fill="black" fill-opacity="0.54"/>
</g>
</svg>
`;

const Icon = {
  trash,
  share,
  ctrl,
  alt,
  link,
}
export default Icon;