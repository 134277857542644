/**
 * 空文字判定
 * @param {*} text 
 */
function isBlankText(text) {
  if (text === null || text === undefined || text === '') {
    return true;
  } else {
    return false;
  }
}

/**
 * 同一ページタイトルの存在判定
 */
function hasSamePageTitle(pages, newTitle, oldTitle) {
  let flag = false;
  pages.forEach((page) => {
    if (page.title === oldTitle) {
      // flagは falseのまま
    } else if (page.title === newTitle.trim()) {
      flag = true;
    }
  })
  return flag;
}

const ValidationRule = {
  isBlankText,
  hasSamePageTitle,
}

export default ValidationRule;
