<template>
  <ModalBase 
    :mask="this.show"
    :modalWidth="500"
    :modalHeight="100"
    @click="this.close('howToModalShow')"
    >
    
  </ModalBase>
</template>

<script>
import ModalBase from './../common/ModalBase.vue';

export default {
  name: 'HowToModal',
  components: {
    ModalBase,
  },
  props: ['selectedPage', 'pages', 'show', 'close'],
}
</script>

<style lang="sass" scoped>
</style>