<template>
  <ModalBase 
    :mask="this.show"
    :modalWidth="480"
    :modalHeight="520"
    @click="this.close('termsAndConditionsModalShow')"
    >
    <div class="modal-header">Terms&amp;Conditions</div>
    <div class="modal-body"><TermsAndConditions /></div>
  </ModalBase>
</template>

<script>
import ModalBase from './../common/ModalBase.vue';
import TermsAndConditions from './TermsAndConditions.vue';

export default {
  name: 'TermsAndConditionsModal',
  components: {
    ModalBase,
    TermsAndConditions,
  },
  props: ['selectedPage', 'pages', 'show', 'close'],
}
</script>

<style lang="sass" scoped>
.modal-header
  padding: 0.6rem
  font-size: 1.2rem
  text-align: center
  border-bottom: 1px solid #ccc

.modal-body
  padding: 1rem 2rem

.block
  display: flex
  align-items: center

  > div:first-child
    margin-right: 5rem
</style>