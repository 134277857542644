<template>
  <ModalBase 
    :mask="this.show"
    :modalWidth="'auto'"
    :modalHeight="'auto'"
    @click="this.close('shortCutKeyListModalShow')"
    >
    <div class="modal-header">Shortcuts</div>
    <div class="modal-body">
      <div class="block">
        <div>ショートカット画面を開く</div>
        <div class="command">
          <!-- <div v-html="Icon.ctrl"></div> -->
          <div>Ctrl</div>
          <div class="plus">+</div>
          <div>\</div>
        </div>
      </div>
      <div class="block">
        <div>ページを上に移動</div>
        <div class="command">
          <!-- <div v-html="Icon.ctrl"></div> -->
          <div>ctrl</div>
          <div class="plus">+</div>
          <div>Alt</div>
          <!-- <div v-html="Icon.alt"></div> -->
          <div class="plus">+</div>
          <div>PageUp</div>
        </div>
      </div>
      <div class="block">
        <div>ページを下に移動</div>
        <div class="command">
          <!-- <div v-html="Icon.ctrl"></div> -->
          <div>ctrl</div>
          <div class="plus">+</div>
          <div>Alt</div>
          <!-- <div v-html="Icon.alt"></div> -->
          <div class="plus">+</div>
          <div>PageDown</div>
        </div>
      </div>
      <div class="block">
        <div>日付の挿入</div>
        <div class="command">
          <div>ctrl</div>
          <div class="plus">+</div>
          <div class="strong">;</div>
        </div>
      </div>
      <div class="block">
        <div>現在時の挿入</div>
        <div class="command">
          <div>ctrl</div>
          <div class="plus">+</div>
          <div class="strong">:</div>
        </div>
      </div>
      <div class="block">
        <div>水平線の挿入</div>
        <div class="command">
          <div>ctrl</div>
          <div class="plus">+</div>
          <div>shift</div>
          <div class="plus">+</div>
          <div>s</div>
        </div>
      </div>
      <div class="block">
        <div>行頭にハイフンを挿入・削除</div>
        <div class="command">
          <div>ctrl</div>
          <div class="plus">+</div>
          <div>k</div>
        </div>
      </div>
      <div class="block">
        <div>行頭にシャープ記号を挿入・削除</div>
        <div class="command">
          <div>ctrl</div>
          <div class="plus">+</div>
          <div>shift</div>
          <div class="plus">+</div>
          <div>k</div>
        </div>
      </div>
      <div class="block">
        <div>上に行を移動</div>
        <div class="command">
          <div>alt</div>
          <div class="plus">+</div>
          <div class="strong">↑</div>
        </div>
      </div>
      <div class="block">
        <div>下に行を移動</div>
        <div class="command">
          <div>alt</div>
          <div class="plus">+</div>
          <div class="strong">↓</div>
        </div>
      </div>
      <div class="block">
        <div>フォントサイズ拡大</div>
        <div class="command">
          <div>ctrl</div>
          <div class="plus">+</div>
          <div class="strong">↑</div>
        </div>
      </div>
      <div class="block">
        <div>フォントサイズ縮小</div>
        <div class="command">
          <div>ctrl</div>
          <div class="plus">+</div>
          <div class="strong">↓</div>
        </div>
      </div>
    </div>
  </ModalBase>
</template>

<script>
import ModalBase from './../common/ModalBase.vue';
import Icon from './../../util/icon.js';

export default {
  name: 'ShortCutKeyListModal',
  components: {
    ModalBase,
  },
  props: ['selectedPage', 'pages', 'show', 'close'],
  data() {
    return {
      Icon,
    }
  }
}
</script>

<style lang="sass" scoped>
.modal-header
  padding: 0.6rem
  font-size: 1.2rem
  text-align: center
  border-bottom: 1px solid #ccc

.modal-body
  padding: 1rem 1.5rem

.block
  display: flex
  align-items: center
  margin-bottom: 0.2rem
  padding: 0.2rem 0.5rem
  border-radius: 3px
  font-size: 0.9rem
  cursor: default

  &:hover
    background-color: hsl(150, 68%, 88%)

  > div:first-child
    width: 320px

.command
  display: flex
  align-items: center

  .plus
    padding: 0 4px

  > div
    display: flex
    align-items: center

.strong
  font-weight: bold
</style>