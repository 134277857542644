<template>
  <div id="main">
    <textarea class="memo-body" v-model="content" style="resize: none"
      @input="save"
      @keydown.exact.ctrl.;.prevent="inputDate"
      @keydown.exact.ctrl.:.prevent="inputTime"
      @keydown.exact.tab.prevent="insertTab"
      @keydown.exact.ctrl.s.prevent="showTest"
      @keydown.exact.ctrl.shift.s.prevent="insertSeparator"
      @keydown.exact.ctrl.k.prevent="insertBullet"
      @keydown.exact.ctrl.shift.k.prevent="insertHashtag"
      @keydown.exact.ctrl.down.prevent="fontSizeDown"
      @keydown.exact.ctrl.up.prevent="fontSizeUp"
      @keydown.exact.alt.up.prevent="lineUp"
      @keydown.exact.alt.down.prevent="lineDown"
      @keydown.exact.ctrl.alt.page-up.prevent="pageUp"
      @keydown.exact.ctrl.alt.page-down.prevent="pageDown"
      @keydown.exact.ctrl.c="copyLine"
    >
    </textarea>
  </div>
  <AutoSaved />
</template>

<script>
// import config from './../../config.js';
import InputUtil from './InputUtil.js'
// import { lineHighlight } from './InputUtil.js'
import AutoSaved from './AutoSaved.vue'
import { getAppData } from './../../util/dataUtil.js';

export default {
  name: 'Main',
  components: {
    AutoSaved,
  },
  data() {
    return {
      copied: '',
    }
  },
  props: ['selectedPage', 'pages', 'changeFontSize'],
  emits: ['save', 'page-up', 'page-down',],
  methods: {
    ...InputUtil,
    save() {
      const data = {
        memo: this.content,
      }
      this.$emit('save', data);
      // lineHighlight();
    },
    pageUp() {
      const eventName = 'page-up';
      
      // 現在のページ番号を取得
      let pageNum;
      this.pages.forEach((page, i) => {
        if (page.title == this.selectedPage.title) {
          pageNum = i;
        }
      })

      if (pageNum === 0) {
        return;
      }

      const emit = {
        eventName,
        data: {
          pageNum,
        }
      };
      this.$emit(eventName, emit);
    },
    pageDown() {
      const eventName = 'page-down';

      // 現在のページ番号を取得
      let pageNum;
      this.pages.forEach((page, i) => {
        if (page.title == this.selectedPage.title) {
          pageNum = i;
        }
      })

      if (pageNum === this.pages.length - 1) {
        return;
      }

      const emit = {
        eventName,
        data: {
          pageNum,
        }
      };
      this.$emit(eventName, emit);
    }
  },
  mounted() {
    const textarea = document.querySelector('.memo-body');
    textarea.value = this.selectedPage.body;

    // 文字の大きさを決める
    const appData = getAppData();
    textarea.style.fontSize = `${appData.config.fontSize}`;


  },
  updated() {
    const textarea = document.querySelector('.memo-body');
    textarea.value = this.selectedPage.body;
  }
}

</script>

<style lang="sass" scoped>
#main
  flex: 1
  display: flex
  min-width: 500px
  height: 100%
  // background-color: hsl(120, 70%, 91%)
  background-color: #f0f0f0
  position: relative

.memo-body
  flex: 1
  padding: 2rem
  border: none
  width: 100%
  border-radius: 2px
  background-color: #fcfcfc
  overflow-y: scroll
  font-size: 1.4rem
  line-height: 1.3
  // box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2)
  
  &:focus
    outline: none

  /*スクロールバー全体*/
::-webkit-scrollbar
  width: 8px

/*スクロールバーの軌道*/
::-webkit-scrollbar-track 
  border-radius: 8px

/*スクロールバーの動く部分*/
::-webkit-scrollbar-thumb 
  background-color: #ccc
  border-radius: 8px

#line-highlight
  position: absolute
  top: 0
  left: 0
  height: 16px
  width: 100%
  background-color: rgba(0, 0, 0, 0.1)
  line-height: 1.5

</style>