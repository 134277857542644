<template>
<p class="has-line-data" data-line-start="0" data-line-end="1">本サービスにおけるユーザーについての個人情報の取り扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」）を定めます。ユーザーは、本サービスの利用をもって本プライバシーポリシーに同意したものとみなします。</p>
<ol>
<li class="has-line-data" data-line-start="2" data-line-end="9">
<p class="has-line-data" data-line-start="2" data-line-end="4">個人情報<br>
本ポリシーにおける個人情報とは、ユーザーの識別にかかる情報、通信サービス上の行動履歴、そのユーザーの端末においてユーザーまたはユーザーの端末に関連して生成または蓄積された以下のような情報です。</p>
<ul>
<li class="has-line-data" data-line-start="5" data-line-end="6">ユーザーから提供される、氏名、メールアドレス、その他運営者のが定める入力フォームにユーザーが入力する情報。</li>
<li class="has-line-data" data-line-start="6" data-line-end="7">本サービスへのアクセス状況やその他の利用方法から収集する情報</li>
<li class="has-line-data" data-line-start="7" data-line-end="9">Googldアナリティクス等から収集するユーザー属性情報（年齢、性別、興味や関心など）</li>
</ul>
</li>
<li class="has-line-data" data-line-start="9" data-line-end="21">
<p class="has-line-data" data-line-start="9" data-line-end="11">利用目的<br>
ユーザーから取得した利用者情報は、以下の目的のために利用されます。</p>
<ul>
<li class="has-line-data" data-line-start="12" data-line-end="13">本サービスの提供、維持、保護及び改善のため</li>
<li class="has-line-data" data-line-start="13" data-line-end="14">本サービスに関するご案内、お問い合わせ等への対応のため</li>
<li class="has-line-data" data-line-start="14" data-line-end="15">本サービスに関する当社の規約、ポリシー等（以下「規約等」）に違反する行為への対応のため</li>
<li class="has-line-data" data-line-start="15" data-line-end="16">本サービスに関する規約等の変更などを通知するため</li>
<li class="has-line-data" data-line-start="16" data-line-end="18">当社のサービスに関連して、個人を特定できない形式に加工した統計データを作成するため<br>
当社または第三者の広告配信または表示のため</li>
<li class="has-line-data" data-line-start="18" data-line-end="19">その他マーケティングに利用するため</li>
<li class="has-line-data" data-line-start="19" data-line-end="21">上記の利用目的に付随する利用目的のため</li>
</ul>
</li>
<li class="has-line-data" data-line-start="21" data-line-end="29">
<p class="has-line-data" data-line-start="21" data-line-end="23">第三者提供<br>
運営者は、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめユーザーの同意を得ないで、第三者（個人情報の保護に関する法律第23条に定める「第三者」をいいます）に提供しません。ただし、次に掲げる場合はこの限りではありません。</p>
<ul>
<li class="has-line-data" data-line-start="24" data-line-end="25">当社が利用目的の達成に必要な範囲内において個人情報の取り扱いの全部または一部を委託する場合</li>
<li class="has-line-data" data-line-start="25" data-line-end="26">合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
<li class="has-line-data" data-line-start="26" data-line-end="27">国の機関もしくは地方公共団体、またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合</li>
<li class="has-line-data" data-line-start="27" data-line-end="29">その他、個人情報保護法その他の法令で認められる場合</li>
</ul>
</li>
<li class="has-line-data" data-line-start="29" data-line-end="31">
<p class="has-line-data" data-line-start="29" data-line-end="31">個人情報の訂正削除<br>
運営者はユーザーにより、個人情報の訂正、削除、利用停止を求められた場合には、必要な調査を行った上で、個人情報の内容の訂正、削除、利用停止を行い、その旨をユーザーに通知します。なお、合理的な理由に基づいて訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知します。</p>
</li>
</ol>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
}
</script>

<style lang="sass" scoped>
p,
li
  font-size: 0.8rem
  padding: 0.2rem 0
ol
  padding-left: 0
</style>
