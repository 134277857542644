<template>
  <div 
    v-show="this.options.show"
    class="dropdown" 
    :style="{ ...position }"
    >
    <slot>default dropdown</slot>
  </div>
</template>

<script>
export default {
  name: 'DropdownBase',
  data() {
    return {
      // size: {
      //   width: setDropdownSize('width', this.width),
      //   height: setDropdownSize('height', this.height),
      // },
      position: {
        // left: `${this.options.x}px`,
        left: '',
        top: `${this.options.y}px`,
      }
    }
  },
  // props: [ 'show', 'x', 'y', 'close' ],
  props: [ 'options' ],
  mounted() {
    // x座標の設定
    const dropdown = document.querySelector('.dropdown');
    this.position.left = `${this.options.x - dropdown.style.width}px`;

    // ドロップダウンを消すイベントの設定
    const vm = this;
    document.addEventListener('click', function f() {
      if (vm.options.show) {
        vm.options.close();
      }
    });
  },
  updated() {
    // data関数の変数自体を書き換えることはできないので、updated関数内でデータをアップデートする。
    const dropdown = document.querySelector('.dropdown');
    const width = dropdown.clientWidth;
    // const height = dropdown.clientHeight;
    this.position.left = `${this.options.x - width}px`;
    this.position.top = `${this.options.y}px`;
  }
}

/**
 * モーダルのサイズを設定
 */
// function setDropdownSize(type, size) {
//   if (size == 'auto') {
//     return size;
//   } else if (parseInt(size) > 0) {
//     return size.toString() + 'px';
//   } else {
//     if (type == 'width') {
//     console.log(size)
//       return '500px';
//     } else {
//       return '300px';
//     }
//   }
// }

</script>

<style lang="sass" scoped>
.dropdown
  position: absolute
  background-color: #fff
  border-radius: 3px
  z-index: 1050
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.18)
  animation: openDropdown 0.15s
  animation-fill-mode: forwards

/*スクロールバー全体*/
::-webkit-scrollbar
  width: 6px

/*スクロールバーの軌道*/
::-webkit-scrollbar-track

/*スクロールバーの動く部分*/
::-webkit-scrollbar-thumb
  background-color: rgba(0, 0, 50, .5)
  border-radius: 6px
  box-shadow: 0 0 0 1px rgba(255, 255, 255, .3)

</style>

<memo>
コンポーネントの呼出時には以下のプロパティを設定
:mask="SampleModalMask"
:closeModal="closeModal" *対応する関数も作成する必要あり。
  closeModal() {
    this.SampleModalMask = false;
  };
:width="" 任意
:height="" 任意
</memo>