import { render } from "./ShareLinkDropdown.vue?vue&type=template&id=912e27d0&scoped=true"
import script from "./ShareLinkDropdown.vue?vue&type=script&lang=js"
export * from "./ShareLinkDropdown.vue?vue&type=script&lang=js"

import "./ShareLinkDropdown.vue?vue&type=style&index=0&id=912e27d0&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-912e27d0"
/* custom blocks */
import block0 from "./ShareLinkDropdown.vue?vue&type=custom&index=0&blockType=memo"
if (typeof block0 === 'function') block0(script)


export default script