<template>
  <div class="footer">
    <div @click="openModal('shortCutKeyListModalShow')" class="shortcuts">Shortcuts（ctrl+\）</div>
    <!-- <div @click="openModal('termsAndConditionsModalShow')">Terms&amp;Conditions</div> -->
    <div @click="openModal('privacyPolicyModalShow')">Privacy Policy</div>
  </div>
  <HowToModal :selectedPage="this.selectedPage" :pages="this.pages" :show="howToModalShow" :close="closeModal"/>
  <ShortCutKeyListModal :selectedPage="this.selectedPage" :pages="this.pages" :show="shortCutKeyListModalShow" :close="closeModal"/>
  <TermsAndConditionsModal :selectedPage="this.selectedPage" :pages="this.pages" :show="termsAndConditionsModalShow" :close="closeModal"/>
  <PrivacyPolicyModal :selectedPage="this.selectedPage" :pages="this.pages" :show="privacyPolicyModalShow" :close="closeModal"/>
</template>

<script>
import HowToModal from './HowToModal.vue';
import ShortCutKeyListModal from './ShortCutKeyListModal.vue';
import TermsAndConditionsModal from './TermsAndConditionsModal.vue';
import PrivacyPolicyModal from './PrivacyPolicyModal.vue';

export default {
  name: 'Footer',
  components: {
    HowToModal,
    ShortCutKeyListModal,
    TermsAndConditionsModal,
    PrivacyPolicyModal,
  },
  props: ['selectedPage', 'pages'],
  data() {
    return {
      howToModalShow: false,
      shortCutKeyListModalShow: false,
      termsAndConditionsModalShow: false,
      privacyPolicyModalShow: false,
    }
  },
  methods: {
    openModal(modal) {
      this[modal] = true;
    },
    closeModal(modal) {
      this[modal] = false;
    }
  },
  mounted() {
    // ショートカット一覧のモーダルをクイック表示
    document.addEventListener('keydown', (e) => {
      if (e.ctrlKey && e.key == '\\') {
        if (this.shortCutKeyListModalShow) {
          this.shortCutKeyListModalShow = false;
        } else {
          this.shortCutKeyListModalShow = true;
        }
      }
    })
  }
}
</script>

<style lang="sass" scoped>
.footer
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 0.2rem 0
  width: 100%
  border-top: 1px solid #ddd
  background-color: #f0f0f0
  color: #777
  font-size: 0.85rem

  > div
    margin-right: 1rem
    cursor: pointer

    &:last-child
      margin-right: 2

.shortcuts
  padding: 0.1rem 0.3rem
  color: #fff
  background-color: hsl(200, 100%, 38%)
  border-radius: 16px
  cursor: pointer !important

</style>